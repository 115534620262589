/**
 * Login Page
 */

// Core imports
import React from 'react';
import PropTypes from 'prop-types';

// Style, SEO and settings
import Helmet from 'react-helmet';
import {
  Col,
  Grid,
  Row,
  styled,
  withTheme,
  thd,
  up,
  css,
} from '@smooth-ui/core-sc';

// Additional Components/Containers
import LoadAsync from '_platform/src/utils/LoadAsync';
import PublicComponent from '_platform/src/utils/PublicComponent';
import getReturnUrlPath from '_platform/src/utils/getReturnUrlPath';

import { withSettings } from 'containers/WebApp/SettingsContext';
import bgImage1x from './images/Login-Page@1x.jpg';
import bgImage2x from './images/Login-Page@2x.jpg';
import logo from './images/Login-Logo@2x.png';

import FormStyles from 'components/CustomComponents/FormStyles/FormStyles';

const LoginProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginProvider" */ '_platform/src/containers/LoginProvider/LoginProvider'
  )
);
const LoginForm = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginForm" */ '_platform/src/components/LoginForm/LoginForm'
  )
);

const PageContainer = styled.div`
  align-items: center;
  background-color: #131d28;
  background-image: url(${bgImage1x});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-color: #131d28;
    background-image: url(${bgImage2x});
  }
`;

const CenterGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

const LoginLogo = styled.h1`
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 1rem;
  padding-top: 0;
  img {
    height: auto;
    width: 222px;
    ${up(
      'sm',
      css`
        width: 400px;
      `
    )};
  }
`;

const IntroContainer = styled.div`
  background-color: ${thd('bodyBackground', '#F4EFEA')};
  border-radius: 40px;
  box-sizing: border-box;
  color: ${thd('secondary', '#203C70')};
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding: 2rem;
  h1 {
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
  }
  a {
    color: ${thd('secondary', '#203C70')};
    &:hover,
    &:focus {
      text-decoration: none;
    }
    &:visited {
      color: ${thd('secondary', '#203C70')};
    }
  }
`;

const LoginContainer = styled.div`
  label.sui-label {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .reset-password {
    a {
      font-size: 14px;
      font-weight: normal;
    }
  }
`;

const LoginPage = ({ settings }) => (
  <PublicComponent
    redirectOnError={
      // Redirect to returnUrl, if it's not the same as loginPagePath
      (getReturnUrlPath !== settings.settingsApp.loginPagePath &&
        getReturnUrlPath) ||
      '/'
    }
  >
    <Helmet>
      <title>Login</title>
      {/*<meta name="description" content="Page description" />*/}
    </Helmet>

    <PageContainer>
      <Grid>
        <CenterGrid>
          <Row justifyContent="center">
            <Col xs={12} sm={10} md={6}>
              <IntroContainer>
                <LoginLogo className="text--center">
                  <img
                    src={logo}
                    alt={
                      settings.settingsApp && settings.settingsApp.siteName
                        ? settings.settingsApp.siteName
                        : 'ProgramName'
                    }
                  />
                </LoginLogo>
                <LoginContainer>
                  <h1>Welcome to Saputo Select</h1>
                  <p>
                    Please login below using the details already provided to
                    you.
                  </p>

                  <FormStyles>
                    <LoginProvider disableMeta>
                      <LoginForm
                        formLabels={{
                          labelUserName: 'Username:',
                          labelPassword: 'Password:',
                        }}
                      />
                    </LoginProvider>
                  </FormStyles>
                </LoginContainer>
              </IntroContainer>
            </Col>
          </Row>
        </CenterGrid>
      </Grid>
    </PageContainer>
  </PublicComponent>
);

LoginPage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withTheme(withSettings(LoginPage));
