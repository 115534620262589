import { thd, styled } from '@smooth-ui/core-sc';

const FormStyles = styled.div`
  label.sui-label {
    color: #4a4a4a;
  }

  input.sui-input,
  textarea {
    border-radius: 20px;
    border: 1px ${thd('grey', '#979797')} solid;
    background-color: white;
  }

  .rs__control {
    border-radius: 20px !important;
    border: 1px ${thd('grey', '#979797')} solid !important;
    background-color: white !important;
  }

  textarea {
    border-radius: 15px;
  }

  button.sui-button,
  .sui-button,
  .-pagination button {
    border-radius: 30px !important;
    background-color: ${thd('secondary', '#203C70')} !important;
    font-weight: bold;
    color: white !important;
    &:hover:enabled,
    &:focus:enabled {
      background-color: ${thd('primary', '#EC1C24')} !important;
    }
  }

  input:checked + .sui-checkbox-content {
    background-color: ${thd('secondary', '#203C70')} !important;
    border-color: ${thd('secondary', '#203C70')} !important;
  }

  input + .sui-radio-content,
  input:checked + .sui-radio-content {
    border-color: ${thd('secondary', '#203C70')} !important;
  }

  input:checked + .sui-radio-content .sui-radio-circle {
    background-color: ${thd('secondary', '#203C70')} !important;
  }
`;

export default FormStyles;
